@import 'https://fonts.googleapis.com/css?family=Share+Tech+Mono';
@font-face {
  font-family: 'Digital';
  src: url('//db.onlinewebfonts.com/t/8e22783d707ad140bffe18b2a3812529.eot');
  src: url('//db.onlinewebfonts.com/t/8e22783d707ad140bffe18b2a3812529.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/8e22783d707ad140bffe18b2a3812529.woff2')
      format('woff2'),
    url('//db.onlinewebfonts.com/t/8e22783d707ad140bffe18b2a3812529.woff')
      format('woff'),
    url('//db.onlinewebfonts.com/t/8e22783d707ad140bffe18b2a3812529.ttf')
      format('truetype'),
    url('//db.onlinewebfonts.com/t/8e22783d707ad140bffe18b2a3812529.svg#Digital-7')
      format('svg');
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  a{
    text-decoration: none;
  }
}

#calculator{
  width: 320px;
  height: 480px;
  background-color: #E6F4FE;
  // color: #fff;
  border:4px solid #1E4B96;
  // outline: 2px solid black;
  
  #display{
    height: 80px;
    width: 100%;

    #input{
      height: 40px;
      line-height: 40px;
      font-size: 22px;
      text-align: end;
      padding-right: 5px;
    }
    #result{
      height: 40px;
      line-height: 40px;
      text-align: end;
      padding-right: 5px;
      font-size: 30px;
      font-family: digital;
    }
  }


  #operate{
    // display: flex;
    // flex-wrap: wrap;
    // align-content:flex-start;
    .operateButton{
      width: 80px;
      height: 80px;
      line-height: 80px;
      background-color: #EFEFEF;
      color: #0154A6;
      outline:1px solid black;
      cursor: pointer;
      position: relative;
      float: left;
    }
    .operateButton:hover{
      filter:brightness(1.1);
      color: #fff;
      background-color: #8cb3f0;
    }
    #clear{
      width: 160px;
      background-color: #d36c63;
      color: #fff;
    }
    #equals{
      height: 160px;
      line-height: 160px;
      top:-80px;
      background-color: #265CB0;
      color: #fff;
    }
    #zero{
      width: 160px;
    }
  }
}

#footer{
  margin-top: 10px;
}